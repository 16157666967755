export class GGAsentence {
// Objektrepresentasjon av GGA-melding fra antenne:
// $GPGGA,123519,4807.038,N,01131.000,E,1,08,0.9,545.4,M,46.9,M,,*47

  identifier: string;
  time: string;
  latitude: string; // degreeMinutes
  latitudeOrientation: string;
  longitude: string; // degreeMinutes
  longitudeOrientation: string;
  fixQuality: number;
  // 0 = invalid
  // 1 = GPS fix (SPS)
  // 2 = DGPS fix
  // 3 = PPS fix
  // 4 = Real Time Kinematic (cpos correction, that is good)
  // 5 = Float RTK
  // 6 = estimated (dead reckoning) (2.3 feature)
  // 7 = Manual input mode
  // 8 = Simulation mode
  satellites: number;
  hdopPrecision: number;
  altitude: string;
  heigthOfGeoid: string;
  sinceLastUpdate: string;
  referenceStationId: string;
  checksum: string;

  /**
   * @param {string} rawGGA eg: "$GPGGA,084307.039,6234.153,N,00741.264,E,0,12,1.0,0.0,M,0.0,M,,*6A"
   * @param {Date} time
   */
  constructor(rawGGA: string, time: Date, userStickHeight: number) {
    // TODO (john) stickHeight variable should be changed in a settings menu
    let stickHeight = 1;
    if (userStickHeight) {
      stickHeight = userStickHeight;
    }
    const values = rawGGA.split(",");
    if (rawGGA.match("GGA")) {
      this.identifier = values[0];
      this.time = values[1];
      this.latitude = values[2];
      this.latitudeOrientation = values[3];
      this.longitude = values[4];
      this.longitudeOrientation = values[5];
      this.fixQuality = parseInt(values[6], 10);
      this.satellites = parseInt(values[7], 10);
      this.hdopPrecision = 0;
      if (values[8] && parseFloat(values[8])) {
        this.hdopPrecision = parseFloat(values[8]);
      }
      this.altitude = "" + (parseFloat(values[9]) - stickHeight);
      this.heigthOfGeoid = values[10];
      this.sinceLastUpdate = values[11];
      this.referenceStationId = values[12];
      this.checksum = values[13];
    }
  }
}
