import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var SettingsService = /** @class */ (function () {
    function SettingsService() {
        this.nightMode$ = new BehaviorSubject(true);
        if (localStorage.getItem('theme') === 'nightMode') {
            this.nightMode$.next(true);
        }
        else {
            this.nightMode$.next(false);
        }
        if (!localStorage.getItem('stickHeight')) {
            localStorage.setItem('stickHeight', '1.0');
        }
    }
    SettingsService.prototype.toggleNightMode = function () {
        this.nightMode$.next(!this.nightMode$.value);
        localStorage.setItem('theme', this.nightMode$.value ? 'nightMode' : 'dayMode');
    };
    SettingsService.prototype.getNightMode = function () {
        return this.nightMode$.asObservable();
    };
    SettingsService.prototype.setStickHeight = function (value) {
        localStorage.setItem('stickHeight', value.toString());
    };
    SettingsService.prototype.getStickHeight = function () {
        return parseFloat(localStorage.getItem('stickHeight'));
    };
    SettingsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SettingsService_Factory() { return new SettingsService(); }, token: SettingsService, providedIn: "root" });
    return SettingsService;
}());
export { SettingsService };
