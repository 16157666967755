import { Injectable } from '@angular/core';
import { remote, shell, ipcRenderer } from 'electron';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ElectronService {
  app: typeof remote;
  shell: typeof shell;
  ipcRenderer: typeof ipcRenderer;
  downloadProgress$ = new Subject();
  downloadProgressAction$ = new Subject();
  count = 0;
  constructor() {
    if (this.isElectron()) {
      this.app = window.require('electron').remote;
      this.shell = window.require('electron').shell;
      this.ipcRenderer = window.require('electron').ipcRenderer;
      this.ipcRenderer.on('download-progress', (event, jsonData) => {
        const data = JSON.parse(jsonData);
        if (data.action === 'show') {
          this.downloadProgressAction$.next('show');
        } else if (data.action === 'hide') {
          this.downloadProgressAction$.next('hide');
        } else {
          this.downloadProgress$.next(data);
        }
      });
    }
  }

  getVersion() {
    if (this.isElectron()) {
      return '0.4.99'; //this.app.app.getVersion();
    } else {
      return 'web:0.4.99';
    }
  }

  open(url) {
    this.shell.openPath(url);
  }

  isElectron = () => {
    return window && window.process && window.process.type;
  }

  openExternalUrl(url: string) {
    this.shell.openExternal(url);
  }
}
