import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../environments/environment';
import { Observable, of, Subject } from 'rxjs';
import { AllotmentHeader } from './allotment-header';
import { Allotment } from './allotment';
import { Order } from './order';
import { UserService } from './user.service';
import { MeasuringPoint } from './measuring-point';
import { Article } from './article';
import { NotesUser } from './notes-user';
import { CompositeArticle } from './composite-article';
import { IsiWeekPerson } from './isi-week-person';
import { OrderComment } from './order-comment';
import { OrderImage } from './i-order-image';

@Injectable()
export class DataService {
  // localhost:8080/orders
  // localhost:8080/orders/team/:teamid
  // localhost:8080/orders/parsell
  proposalActions: Subject<any>;
  cachedOrders;
  apiUrl;
  isAuthenticated = false;
  currentUser: NotesUser;
  constructor(private http: HttpClient, private userService: UserService) {
    this.apiUrl = AppConfig.apiUrl;
  }
  /**
   * @param all pass true to get all team members.
   */
  getTeamMembers(all = false): Observable<Array<IsiWeekPerson>> {
    let url = `${this.apiUrl}/employees`;
    if (!all) {
      url += `/team/${this.userService.teamId}`;
    }
    return this.http.get<any>(url).pipe(map(res => {
      return res.data;
    }));
  }
  getProjects(): Observable<Array<any>> {
    const url = `${this.apiUrl}/projects`;
    return this.http.get<any>(url).pipe(
      map(res => {
        if (res.data) {
          return res.data;
        }
        return [];
      }), catchError(err => {
        console.error("Could not get projects", err);
        return [];
      })
    );
  }
  getOrders(): Observable<Array<Order>> {
    const url = `${this.apiUrl}/orders/open/${this.userService.teamId}`;
    return new Observable<Array<Order>>(observer => {
      // if (this.cachedOrders) {
      //   observer.next(this.cachedOrders);
      // } else {
      this.http.get<any>(url).pipe(map(data => {
        this.cachedOrders = data.data;
        observer.next(data.data);
      })).subscribe();
      // }
    });
  }

  getCompletedOrders(): Observable<Array<Order>> {
    const url = `${this.apiUrl}/orders/completed/` + this.userService.teamId;
    return new Observable<Array<Order>>(observer => {
      // if (this.cachedOrders) {
      //   observer.next(this.cachedOrders);
      // } else {
      this.http.get<any>(url).pipe(map(data => {
        this.cachedOrders = data.data;
        observer.next(data.data);
      })).subscribe();
      // }
    });
  }
  clearCache() {
    this.cachedOrders = null;
  }
  getOrder(unid: string): Observable<Order> {
    const url = `${this.apiUrl}/orders/${unid}/header`;
    return this.http.get<any>(url).pipe(map(res => {
      return res.data;
    }));
  }

  getOrderComments(unid: string): Observable<Array<OrderComment>> {
    const url = `${this.apiUrl}/orders/${unid}/comments`;
    return this.http.get<any>(url).pipe(map(res => {
      return res.data;
    }));
  }

  getOrderImages(unid: string): Observable<Array<OrderImage>> {
    console.log("getOrderImages dataservice");
    const url = `${this.apiUrl}/orders/${unid}/images`;
    return this.http.get<any>(url).pipe(map(res => {
      return res.data;
    }));
  }

  deleteOrderImage(unid: string): Observable<any> {
    const url = `${this.apiUrl}/images/delete/${unid}`;
    return this.http.get<any>(url).pipe(map(res => {
      return res.data;
    }));
  }

  saveSortOrder(sortOrder) : Observable<any> {
    const url = `${this.apiUrl}/orders/sort/${this.userService.teamId}`;
    return this.http.post<any>(url, sortOrder).pipe(map(res => {
      return res.data;
    }));
  }
  saveOrderComment(orderUnid: string, comment: OrderComment): Observable<OrderComment> {
    const url = `${this.apiUrl}/orders/${orderUnid}/comments`;
    return this.http.post<any>(url, comment).pipe(map(res => {
      return res.data;
    }));
  }

  getOrderLines(unid: string): Observable<Array<any>> {
    const url = `${this.apiUrl}/orders/${unid}/orderlines`;
    return this.http.get<any>(url).pipe(map(res => {
      return res.data;
    }));
  }
  saveOrderLine(ol: any): Observable<any> {
    const url = `${this.apiUrl}/orders/${ol.parentId}/orderlines`;
    return this.http.post<any>(url, ol).pipe(map(res => {
      return res.data;
    }));
  }

  getOrderForApproval(orderUnid: string): Observable<any> {
    const url = `${this.apiUrl}/orders/${orderUnid}/approve`
    return this.http.get<any>(url).pipe(map(res => {
      return res.data;
    }))
  }
  saveOrderAsApproved(order: any, orderUnid: string): Observable<any> {
    const url = `${this.apiUrl}/orders/${orderUnid}/approve`;
    return this.http.post<any>(url, order).pipe(map(res => {
      return res.data;
    }));
  }
  getWorkshopTasks(team: string): Observable<Array<any>> {
    const url = `${this.apiUrl}/tasks/workshop/${team}`;
    return this.http.get<any>(url).pipe(map(res => {
      return res.data;
    }));
  }

  saveWorkshopTask(task: any): Observable<any> {
    const url = `${this.apiUrl}/tasks/workshop`;
    return this.http.post<any>(url, task).pipe(map(res => {
      return res.data;
    }));
  }
  getParcels(unid: string): Observable<Array<AllotmentHeader>> {
    const url = `${this.apiUrl}/orders/${unid}/allotments`;
    return this.http.get<any>(url).pipe(map(res => {
      return res.data;
    }));
  }

  newParcel(unid: string): Observable<AllotmentHeader> {
    const url = `${this.apiUrl}/orders/${unid}/allotments/new`;
    return this.http.get<any>(url, {}).pipe(map(res => {
      return res.data;
    }));
  }
  saveAllotment(allotment: Allotment) {
    const url = `${this.apiUrl}/orders/${allotment.orderUnid}/allotments`;
    return this.http.post<any>(url, allotment).pipe(map(res => {
      if (res) {
        return res.data;
      }
      return null;
    }, catchError(err => {
      return null;
    })));
  }
  getArticlesFromCompositeArticle(rtType: string, compositeArticleNum: string, quantity: number): Observable<Array<Article>> {
    const url = `${this.apiUrl}/articles/calc/sa`;
    return this.http.post<any>(url, {
      rtType: rtType,
      saNum: compositeArticleNum,
      saQuantity: '' + quantity
    }).pipe(map(res => {
      return res.data;
    }));
  }
  getCalculatedArticles(railTypeId: string, points: Array<MeasuringPoint>, demolition: boolean = false, invoiceType: string, allotmentUnid: string): Observable<Array<Article>> {
    const url = `${this.apiUrl}/articles/calc/?demolition=` + demolition;
    const data: {
      points: Array<MeasuringPoint>,
      demolitionType?: string,
      rtType?: string,
      invoiceType: string,
      unid: string } = { points: points, invoiceType: invoiceType, unid: allotmentUnid };
    if (demolition) {
      data.demolitionType = railTypeId;
      for (const point of points) {
        point.demolitionType = railTypeId;
      }
    } else {
      data.rtType = railTypeId;
    }
    console.log('calculate articles: ', data);
    return this.http.post<any>(url, data).pipe(map(res => {
      return res.data;
    }));
  }
  getCalculatedArticlesWithoutGps(rtType: string, startType: string, endType: string, allotmentLength: number, sideOfRoad: string, compositeArticles: Array<any>,demolitionType: string, demolitionLength: number, invoiceType: string, unid: string): Observable<Array<Article>> {
    const url = `${this.apiUrl}/articles/calc/`;
    const data = {
      rtType: rtType,
      startType: startType,
      endType: endType,
      allotmentLength: allotmentLength,
      sideOfRoad: sideOfRoad,
      compositeArticles: compositeArticles,
      demolitionType: demolitionType,
      demolitionLength: demolitionLength,
      invoiceType: invoiceType,
      unid: unid
  };
    return this.http.post<any>(url, data).pipe(map(res => {
      return res.data;
    }));
  }
  getStructureArticles(): Observable<{ [key: string]: CompositeArticle }> {
    const url = `${this.apiUrl}/articles/sa`;
    return this.http.get<any>(url).pipe(map(res => {
      return res.data;
    }));
  }
  getWorkArticles(): Observable<Array<Article>> {
    const url = `${this.apiUrl}/articles/maincategories/arbeid`;
    return this.http.get<any>(url).pipe(map(res => {
      return res.data;
    }));
  }
  getArticles(): Observable<{ [key: string]: Article }> {
    const url = `${this.apiUrl}/articles`;
    return this.http.get<any>(url).pipe(map(res => {
      return res.data;
    }));
  }
  getRailTypesCategories() {
    const url = `${this.apiUrl}/articles/rt/cat`;
    return this.http.get<any>(url).pipe(map(res => {
      return res.data;
    }));
  }
  login(user: NotesUser): Observable<{ fullName: string, commonName: string, team: string, status: string, roles: Array<string> }> {
    const url = `${this.apiUrl}/login`;
    return this.http.post<any>(url, user).pipe(map(res => {
      return res.data;
    }));
  }
  logout(): Observable<any> {
    const url = `${this.apiUrl}/login/signout`;
    return this.http.get<any>(url).pipe(map(res => {
      return res.data;
    }));
  }

  attemptAuth(userCredentials: NotesUser): Observable<NotesUser> | any {
    const headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    const redirectToUrl = encodeURIComponent(location.protocol + '//' + window.location.hostname +
      (window.location.port ? ':' + window.location.port : ''));
    const postData = 'username=' + encodeURIComponent(userCredentials.notesName) +
      '&password=' + encodeURIComponent(userCredentials.password) +
      '&redirectTo=' + redirectToUrl;
    return this.http.post(`https://api.agjerde.no/names.nsf?login&redirectTo=${redirectToUrl}`, postData, { headers: headers, withCredentials: true })
      .pipe(map(data => {
        return data;
      })).pipe(catchError(e => {
        if (document.cookie.indexOf('LtpaToken') !== -1) { // assumed logged in.
          this.setAuth(userCredentials);
        }
        return [this.currentUser];
      }));
  }

  setAuth(user: NotesUser) {
    this.currentUser = user;
    this.isAuthenticated = true;
  }

  getLoginUsers(): Observable<Array<NotesUser>> {
    const url = `${this.apiUrl}/login/users`;
    return this.http.get<any>(url).pipe(map(res => res.data), catchError(() => of(null)));
  }
  getReplicationSettins(): Observable<Array<NotesUser>> {
    const url = `${this.apiUrl}/settings/replicate`;
    return this.http.get<any>(url).pipe(map(data => {
      return data.data;
    }));
  }
  getNotesIniDir(): Observable<any> {
    const url = `${this.apiUrl}/login/notesinidir`;
    return this.http.get<any>(url).pipe(map(data => {
      return data.data;
    }));
  }

  getTeamEmployyes(team): Observable<any> {
    const url = `${this.apiUrl}/employees/team/${team}`;
    return this.http.get<any>(url).pipe(map(data => {
      return data.data;
    }));
  }
  getParcelExcluded(orderId,docId): Observable<any> {
    const url = `${this.apiUrl}/orders/${orderId}/allotments/${docId}/exclude`;
    return this.http.get<any>(url).pipe(map(data => {
      return data;
    }));
  }
  getEmployyes(): Observable<any> {
    const url = `${this.apiUrl}/employees`;
    return this.http.get<any>(url).pipe(map(data => {
      return data.data;
    }));
  }

  getCompanyContact(compId: string, name: string): Observable<any> {
    const url = `${this.apiUrl}/contacts/company/${compId}/${name}`;
    return this.http.get<any>(url).pipe(map(data => {
      return data.data;
    }));
  }
  getTeamResources() {
    const url = `${this.apiUrl}/team/info/` + this.userService.teamId;
    return this.http.get<any>(url).pipe(map(res => {
      return res.data;
    }));
  }
  getQualityProposals(): Observable<Array<any>> {
    const url = `${this.apiUrl}/quality/proposals/`;
    return new Observable<Array<any>>(observer => {
      this.http.get<any>(url).pipe(map(data => {
        observer.next(data.data);
      })).subscribe();
    });
  }
  getSingleProposal(proposalId: string) {
    const url = `${this.apiUrl}/quality/proposals/${proposalId}`;
    return this.http.get<any>(url).pipe(map(proposal => {
      return proposal.data;
    }))
  }
  SaveProposalComments(proposalId: string, comment: string): Observable<any> {
    const url = `${this.apiUrl}/quality/proposal/comment`;
    return this.http.post<any>(url, { parentUnid: proposalId, body: comment }).pipe(map(res => {
      // this.proposalActions.next('updated');
      return res;
    }));
  }

  getEmployeeDepartments() {
    const url = `${this.apiUrl}/employees/dep/`;
    return this.http.get<any>(url).pipe(map(res => {
      return res.data;
    }))
  }

  SaveProposal(proposal: any): Observable<any> {
    const url = `${this.apiUrl}/quality/proposal`;
    return this.http.post<any>(url, proposal).pipe(map(res => {
      return res;
    }));
  }

  getNewsItems() {
    const url = `${this.apiUrl}/quality/news/`;
    return this.http.get<any>(url).pipe(map(res => {
      return res.data;
    }))
  }
}
