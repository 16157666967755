import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
var ElectronService = /** @class */ (function () {
    function ElectronService() {
        var _this = this;
        this.downloadProgress$ = new Subject();
        this.downloadProgressAction$ = new Subject();
        this.count = 0;
        this.isElectron = function () {
            return window && window.process && window.process.type;
        };
        if (this.isElectron()) {
            this.app = window.require('electron').remote;
            this.shell = window.require('electron').shell;
            this.ipcRenderer = window.require('electron').ipcRenderer;
            this.ipcRenderer.on('download-progress', function (event, jsonData) {
                var data = JSON.parse(jsonData);
                if (data.action === 'show') {
                    _this.downloadProgressAction$.next('show');
                }
                else if (data.action === 'hide') {
                    _this.downloadProgressAction$.next('hide');
                }
                else {
                    _this.downloadProgress$.next(data);
                }
            });
        }
    }
    ElectronService.prototype.getVersion = function () {
        if (this.isElectron()) {
            return '0.4.99'; //this.app.app.getVersion();
        }
        else {
            return 'web:0.4.99';
        }
    };
    ElectronService.prototype.open = function (url) {
        this.shell.openPath(url);
    };
    ElectronService.prototype.openExternalUrl = function (url) {
        this.shell.openExternal(url);
    };
    ElectronService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ElectronService_Factory() { return new ElectronService(); }, token: ElectronService, providedIn: "root" });
    return ElectronService;
}());
export { ElectronService };
