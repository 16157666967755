import { Injectable, Renderer2 } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

private nightMode$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  constructor() {
    if (localStorage.getItem('theme') === 'nightMode') {
      this.nightMode$.next(true);
    } else {
      this.nightMode$.next(false);
    }
    if (!localStorage.getItem('stickHeight')) {
      localStorage.setItem('stickHeight', '1.0');
    }
  }
  toggleNightMode() {
    this.nightMode$.next(!this.nightMode$.value);
    localStorage.setItem('theme', this.nightMode$.value ? 'nightMode' : 'dayMode');
  }
  getNightMode(): Observable<boolean> {
    return this.nightMode$.asObservable();
  }

  setStickHeight(value:number) {
    localStorage.setItem('stickHeight', value.toString());
  }
  getStickHeight(): number {
    return parseFloat(localStorage.getItem('stickHeight'));
  }
}
