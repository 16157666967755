import {Article} from './article';
import {GGAsentence} from './ggasentence';
import {IsiRoadRef} from './isi-road-ref';
import {RailingType} from './railing-type';
import {CompositeArticle} from './composite-article';
import {GpsData} from './gps-data';
import {POINT_STATUS} from './point-status';
import { SettingsService } from './settings.service';

export class MeasuringPoint {

// "308fe255-5be6-4507-b941-b613c974bfe5"
  uniqueId: string;

// Posisjons-objekt fra NMEA/antenne
  gga: GGAsentence;

// Vegreferanse
  isiRoadRef: IsiRoadRef;

// @Deprecated
  private longitude: string;

// @Deprecated
  private altitude: string;

// @Deprecated
  private positionMeasured: boolean;

// Avstand siden forrige målepunkt
  distanceFromPreviousPoint: number;

// Manuelt angittverdier for å beregne en posisjon ("Lag punkt uten dekning")
// @Deprecated
  private givenLength: string;
// @Deprecated
  private givenPositionKey: string;
// @Deprecated
  private givenKm: string;
// @Deprecated
  private givenDirection: string;

// Kategori rekkverk (for nedtrekksliste)
  railingCategory: string;
// Kategori rekkverk index (for nedtrekksliste)
  railingCategoryIndex: number;
// Rekkverkstype repr (for nedtrekksliste)
  railingType: string;
// Rekkverkstype index (for nedtrekksliste)
  railingTypeIndex: number;

// Rekkverkstype-objekt
  railType: RailingType;

// Merke for riving
  isDemolition: boolean;
// "Montering", "Riving", "Montering og riving": 0, 1, 2?
  state: number;
// Rekkverktype riving
  demolitionType: string;
// Er målepunktet tatt med i et tellepunkt for riving?
  demolitionVerified: boolean;
// Parsell tilstand
  private allotmentState: number;

// Strukturartikler id'er
// @Deprecated
  private compositeArticleIds: Array<string>;

// Strukturartikler nummer

// @Deprecated
  private compositeArticleNums: Array<string>;
// Strukturartikler, objekter
  compositeArticles: Array<CompositeArticle>;
// Index i nedtrekksmeny for valgt strukturartikkel
  compositeArticleIndex: number;


// @Deprecated
  private supportType: string;
// @Deprecated
  private supportTypeIndex: number;

// @Deprecated
  private supportLength: number;

// Plassering
  location: string;

// Plassering index (for nedtrekksliste)
  locationIndex: number;

// @Deprecated
  private supportUsed: boolean;

// @Deprecated
  private stickType: string;
// @Deprecated
  private stickTypeIndex: number;

// @Deprecated
  private stickGap: string;
// @Deprecated
  private stickGapIndex: number;

// @Deprecated
  private stickUsed: boolean;

// Punktet er med i et tellepunkt (artikler beregnet)
  verified: boolean;

// Punktet er et tellepunkt
  verifyArticlesPoint: boolean;


// @Deprecated
  private articles: Array<Article>;
// @Deprecated
  private extraArticles: Array<string>;

// Korrigering for høyde (innstilling i app, som trekker fra 60 cm(?) som er høyden på stanga Getac er montert på)
  altitudeAdjustment: number;

  constructor(gpsData?: GpsData, state?: number, distanceFromPreviousPoint?: number) {
    let stickHeight = 1.0;
    let settingsHeight =  localStorage.getItem('stickHeight')
    if (settingsHeight) {
      stickHeight = parseFloat(settingsHeight)
      console.log("settings set to: ", stickHeight)
    } else {
      console.log('settings not set');
    }

    if (gpsData) {
      this.gga =  new GGAsentence(gpsData.raw, gpsData.time,stickHeight);
    }
    if (distanceFromPreviousPoint) {
      this.distanceFromPreviousPoint = distanceFromPreviousPoint;
    }
    this.state = state;
    this.compositeArticles = [];
    if (state % 10 === POINT_STATUS.DEMOLITION_POINT || state % 10 === POINT_STATUS.DEMOLITION_AND_MEASURE_POINT) { this.isDemolition = true; }
  }
}
