import { Component, OnInit, OnDestroy } from '@angular/core';
import { HeaderService } from '../header/header.service';
import { Map, marker, polyline, LayerGroup, layerGroup, latLng } from 'leaflet';
import * as L from 'leaflet';
import 'leaflet';
import 'leaflet.markercluster'
import { GeoService } from '../../geo.service';
import { ActivatedRoute } from '@angular/router';
import { MediaService } from '../../shared/media.service';
import { UserService } from '../../user.service';
import { Subscription, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { MapService } from '../../map.service';

@Component({
  selector: 'app-team-map',
  templateUrl: './team-map.component.html',
  styleUrls: ['./team-map.component.scss']
})
export class TeamMapComponent implements OnInit, OnDestroy {
  myMap: Map;
  options = {
    zoom: 5,
    maxZoom: 23,
    minZoom: 4,
    center: [65, 17],
  };
  equipmentSubscription: Subscription;
  selectedPointMarker: any;
  teamMarkerGroup: LayerGroup = new LayerGroup();
  equipmentMarkers;
  pos: L.Marker<any>;
  features: any;
  greenIcon = L.icon({
    iconUrl: 'assets/map-icons/greenFlag.ico',
    iconSize: [60, 60], // size of the icon
    iconAnchor: [40, 57], // point of the icon which will correspond to marker's location
    popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
  });
  pinkIcon = L.icon({
    iconUrl: 'assets/map-icons/pinkFlag.ico',
    iconSize: [60, 60], // size of the icon
    iconAnchor: [42, 57], // point of the icon which will correspond to marker's location
    popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
  });
  blackIcon = L.icon({
    iconUrl: 'assets/map-icons/blackPoint.png',
    iconSize: [30, 30], // size of the icon
    iconAnchor: [15, 15], // point of the icon which will correspond to marker's location
    popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
  });
  svgCulvert = '<svg width="100%" height="100%" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd stroke-linejoin:round;stroke-miterlimit:2;"> <circle cx="12" cy="12" r="10" style="fill:none;" /><clipPath id="_clip1"><circle cx="12" cy="12" r="10" /></clipPath> <g clip-path="url(#_clip1)"> <path id="outerCircle" [attr.fill]="isNightMode?"white":"black"" d="M12,2C17.519,2 22,6.481 22,12C22,17.519 17.519,22 12,22C6.481,22 2,17.519 2,12C2,6.481 6.481,2 12,2ZM12,4C16.415,4 20,7.585 20,12C20,16.415 16.415,20 12,20C7.585,20 4,16.415 4,12C4,7.585 7.585,4 12,4Z" /></g> <g transform="matrix(1.07692,0,0,1.07692,0.692308,-2.53846)"><circle cx="10.5" cy="13.5" r="6.5" style="fill:none;" /><clipPath id="_clip2"><circle cx="10.5" cy="13.5" r="6.5" /></clipPath><g clip-path="url(#_clip2)"><g transform="matrix(0.928571,0,0,0.928571,-0.642857,2.35714)"><path id="waves" [attr.fill]="isNightMode?"white":"black"" d="M4,14C4.097,13.6 5.333,13 6,13C6.667,13 7.333,14 8,14C8.667,14 9.333,13 10,13C10.667,13 11.333,14 12,14C12.667,14 13.333,13 14,13C14.667,13 15.333,14 16,14C16.667,14 17.333,13 18,13C18.667,13 19.91,13.577 20,14C20.09,14.423 19.01,15.012 18.538,15.537C18.066,16.062 17.735,16.732 17.167,17.152C16.275,17.811 14.534,19.143 13.186,19.49C11.857,19.832 10.054,19.486 9.077,19.235C8.381,19.056 7.818,18.503 7.322,17.983C6.713,17.344 5.975,16.064 5.421,15.4C4.995,14.89 3.903,14.4 4,14Z" /></g> </g></g>';
  urlCulvert = encodeURI("data:image/svg+xml," + this.svgCulvert).replace(new RegExp('#', 'g'), '%23');
  /* CustomIcon = L.icon({
      iconUrl: this.urlCulvert,
      iconSize:     [40, 40],
      shadowSize:   [50, 64],
      iconAnchor:   [22, 94],
      shadowAnchor: [4, 62],
      popupAnchor:  [-3, -76]
  }); */
  customIcon = L.divIcon({ html: this.svgCulvert,  iconSize: [24, 24], iconAnchor: [12, 12] });
  equipmentIconUrl = 'assets/map-icons/greenTruck.png';
  orderUnid: string;
  markers: L.MarkerClusterGroup;
  isMobileView: boolean;
  equipmentsList = [];
  constructor(private headerService: HeaderService, private geoService: GeoService,
    private activatedRoute: ActivatedRoute, private mediaService: MediaService,
    private user: UserService, private mapService: MapService) {
    this.mediaService.getMedia().subscribe(media => {
      this.isMobileView = media.isMobileView;
    });
    this.orderUnid = this.activatedRoute.snapshot.params['orderUnid'];
  }

  ngOnInit() {
    this.headerService.addNavItem({
      route: `/teammap`,
      label: `Kart`,
      queryParams: ''
    });

  }

  ngOnDestroy() {
    this.headerService.removeNavItem({
      route: `/teammap`,
      label: `Kart`,
      queryParams: ''
    });
    if (this.equipmentSubscription) {
      this.equipmentSubscription.unsubscribe();
    }
  }
  async onMapReady(map: L.Map): Promise<void> {
    this.myMap = map;
    map.setView(L.latLng([62.5364071, 7.888097]), 11)
    const vector = await this.mapService.getVectorTileLayer();
    vector.addTo(map);
    this.teamMarkerGroup = layerGroup().addTo(this.myMap);
    if (this.orderUnid == undefined) { // map for team
      this.geoService.getCulvertsPos().subscribe(res => {
        if(res.features.length > 0) this.createCulvertMarkers(res.features);
      })
      this.geoService.getTeamPos().subscribe(res => {
        this.createPolylines(res);
        this.getEquipmentList(this.user.teamId);
      });

    } else { // map for order
      this.geoService.getOrderCulvertsPos(this.orderUnid).subscribe(res => {
        if(res.features.length > 0) this.createCulvertMarkers(res.features);
      });
      this.geoService.getOrderPos(this.orderUnid).subscribe(res => {
        this.createPolylines(res);
        this.getEquipmentList(this.user.teamId);
      });

    }
  }
  createCulvertMarkers(culvertPoints:Array<any>) {
    culvertPoints.forEach(data => {
      var points = data.geometry.coordinates;
      var roadRef = data.properties.roadRefs;
      var properties = data.properties.culvertProperties;

      let roadRefMsg = "";
      let propertiesMsg = "";
      properties.forEach(data => {
        propertiesMsg = propertiesMsg + data.label + ': ' + data.value + '<br>';
      });
      if(data.geometry.type == "Point") {
        points[0].pop();
        roadRefMsg = roadRef[0].shortForm;
        marker(latLng(points[0][0], points[0][1]),{icon: this.customIcon }).addTo(this.myMap).bindPopup('Stikkrenne: ' + roadRefMsg + '<hr>' + propertiesMsg);
      } else if(data.geometry.type == "LineString") {
        points.map(data => { data.pop() });
        var i = 0;
        points.forEach(point => {
          roadRefMsg = roadRef[i].shortForm;
          marker(latLng(point[0],point[1]), {icon: this.customIcon}).addTo(this.myMap).bindPopup('Stikkrenne: ' + roadRefMsg + '<hr>' + propertiesMsg);
          i++;
        });
        polyline(points,{ color: 'black', weight: 10 }).addTo(this.myMap).bindPopup('Stikkrenne: <hr>' + propertiesMsg);
      }
    })
  }

  getEquipmentList(teamId: string) {
    let coord = [];
    this.equipmentSubscription = timer(0, 15000).pipe(
      switchMap(() => this.geoService.getEquipmentPos()
      )).subscribe(res => {
        // remove all existing markers from map, before adding new ones
        if (this.equipmentMarkers) {
          this.equipmentMarkers.forEach(mmarker => {
            mmarker.remove();
          });
          this.equipmentMarkers = [];
        } else {
          this.equipmentMarkers = [];
        }
      res.forEach(data => {
        let isCurrentTeam = false;
        if (data.groups !== undefined) {
          data.groups[0].name.indexOf('Lag-' + teamId) > -1 ? isCurrentTeam = true : isCurrentTeam = false;
          data.groups[0].name.indexOf('Lag ' + teamId) > -1 ? isCurrentTeam = true : isCurrentTeam = false;
          
        }
        if (data.groups !== undefined && isCurrentTeam && data.status) {
          this.equipmentsList.push(data);
          const speed = data.status.location.speed == null ? 0 : data.status.location.speed;
          const lat = data.status.location.point.lat;
          const lon = data.status.location.point.lon;
          this.equipmentIconUrl = speed > 5 ? 'assets/map-icons/greenTruck.png' : 'assets/map-icons/redTruck.png';
          if (data.type === "6Rekkverksutstyr") {
            this.equipmentIconUrl = 'assets/map-icons/trafficlight.png';
          } else if (data.type === "7Minimaskiner") {
            this.equipmentIconUrl = 'assets/map-icons/excavator.png';
          } else if (data.type === "6Tilhengere") {
            this.equipmentIconUrl = 'assets/map-icons/trailer.png';
          };
          const Icon = L.icon({
            iconUrl: this.equipmentIconUrl,
            iconSize: [30, 30], // size of the icon
            iconAnchor: [15, 30], // point of the icon which will correspond to marker's location
            popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
          });
          const formattedDate = this.getFormatedTime(data.status.time.received);
          const dirIcon = this.getDirectionIcon(data.status.location.direction, speed);
          const popupMsg = data.name + '<hr>' + data.registration_id + '<br>Fart: ' +
            speed + ' km/t<br>Retning: ' + dirIcon + '<hr> Sist oppdatert: ' + formattedDate;

          const eqMarker: L.Marker = marker(latLng(lat, lon), { icon: Icon }).addTo(this.myMap).bindPopup(popupMsg);

          this.equipmentMarkers.push(eqMarker)
          coord.push([lat, lon]);
        }
      });
    })
  }
  getFormatedTime(receivedtime: any) {
    let time = new Date(receivedtime);
    return Intl.DateTimeFormat('nb', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric'
    }).format(time);
  }
  getDirectionIcon(direction, speed) {
    var dir = "";
    var deg = direction;

    if (speed <= 2) {
      dir = "none"
    } else {
      if (deg > 32 && deg <= 67) {
        dir = "ne";
      } else if (deg <= 112) {
        dir = "e";
      } else if (deg <= 148) {
        dir = "se";
      } else if (deg <= 202) {
        dir = "s";
      } else if (deg <= 247) {
        dir = "sw";
      } else if (deg <= 292) {
        dir = "w";
      } else if (deg < 337) {
        dir = "nw";
      } else {
        dir = "n";
      }
    }
    var imgName = dir + ".GIF";
    var imgSrc = "<img width='15px' style='padding-top: 4px; vertical-align: top;' src='assets/direction-arrows/" + imgName + "'/>"
    return imgSrc;
  }
  curvertLines(culvert: any[]) {
    // console.log(culverts);
    let coord = [];
    culvert.forEach(function (c, i) {
      const coordArray = c[0].split(' ');
      coordArray.pop();
      coord.push(coordArray);
    });
    if (coord.length > 1) {
      coord.forEach(points => {
        marker(latLng(points), { icon: this.customIcon }).addTo(this.myMap);
      })
    }
    else {
      marker(latLng(coord[0]), { icon: this.customIcon }).addTo(this.myMap);
    }
  }
  createPolylines(res: any) {
    let features = res.features;
    this.markers = L.markerClusterGroup({
      showCoverageOnHover:false,
      zoomToBoundsOnClick: true,
      spiderfyOnMaxZoom: false,
      disableClusteringAtZoom: 14
    });

    if (features && features.length > 0) {
      features.forEach(data => {
        let latlng = data.geometry.coordinates;
        let popMsg = this.getPopupMessage(data.properties);
        let markerPopup = L.popup().setContent(popMsg);
        var startPoint:any  = [];
        var startMarker:L.Marker;
        latlng.map(data => { data.pop() });
        var startPoint = latlng[0];
        if (data.geometry.type == "LineString") {
          const endPoint = latlng[latlng.length - 1];
          var endMarker = marker(endPoint, { icon: this.pinkIcon });
          startMarker = marker(startPoint, { icon: this.greenIcon });
          var pl =  polyline(latlng, { color: 'green', weight: 10, dashArray: '20' });
          L.featureGroup([startMarker, endMarker, pl])
            .bindPopup(markerPopup)
            .addTo(this.teamMarkerGroup) // map cluster
        }
        else if(data.geometry.type == "Point"){
          startMarker = marker(startPoint, {icon: this.greenIcon }).addTo(this.teamMarkerGroup).bindPopup(markerPopup);
        }
        this.markers.addLayer(startMarker);
      })
      let coordinates = features.map(data => { return data.geometry.coordinates });
      coordinates.concat(coordinates);
      this.teamMarkerGroup.addLayer(this.markers);
      this.myMap.fitBounds(coordinates); // to set the zoom level based on the coordinates
    }
  }
  getPopupMessage(properties) {
    const popupHeader = '<strong>Ordre ' + properties.order + ' ' +
      properties.orderSubject
      + '<br>parsell ' + properties.parsell
      + '</strong>';
    const popupContent: string = 'Lengde: ' + properties.lengde
      + '<br>Vegref start: ' + properties.startRoadRef
      + '<br>Vegref slutt: ' + properties.endRoadRef
      + '<br>Type: ' + properties.rtType
      + '<br>Riving: ' + properties.rivLengde + 'm'
      + '<br>Starttype: ' + properties.startType
      + '<br>Endetype: ' + properties.endType;
    const popupMsg = popupHeader + ' <hr>' + popupContent;
    return popupMsg;
  }

  onMapCLick($event: L.LeafletEvent): void {
    throw new Error("Method not implemented.");
  }
  private onPopupClose(event) {
    this.selectedPointMarker = null;
  }

}
