<div class="order mat-typography" [class.mobile-view]="isMobileView">
  <h1 class="my-h1">{{order?.snum}} {{order?.subject}}</h1>
  <button type="button" class="my-h2" mat-button (click)="gotoOrderMap()"> Vis i kart <mat-icon> map </mat-icon>
  </button>
  <mat-accordion [multi]="true" [class.mobile-view]="isMobileView">
    <mat-expansion-panel [hideToggle]="true" [expanded]="step === 0">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Ordreinfo
        </mat-panel-title>
        <mat-panel-description>
          &nbsp;
          <mat-icon>work</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div>
        <!-- <div class="order-title">
          <div class="avatar example-header-image"></div>
          <span class="title"> {{order?.author}} </span>
          <span class="order-date">{{order?.created}}</span>
        </div> -->
        <div class="card-content-container">
          <div class="description">{{order?.description}}</div>
          <div class="comment">
            <div class="title">Info til laget: </div>
              <div style="font-style: italic">
              <mat-icon>format_quote</mat-icon>{{order?.woComment}}
              </div>
          </div>
          
          <div class="cabeldetection">
            <h3 style="grid-row: 2; grid-column: 1">Kabelpåvisning</h3>
            <div class="power">Strømkabler</div>
            <div style="grid-row: 3; grid-column: 2">
              <mat-icon *ngIf="order?.cableDetection?.power"
                [ngClass]="{'active' : order?.cableDetection?.power?.status === '0', 'detected' : order?.cableDetection?.power?.status === '1'}">
                {{getCableStatusIcon(order?.cableDetection?.power?.status)}}
              </mat-icon>
              <mat-icon color='accent' *ngIf="!order?.cableDetection?.power"> help </mat-icon>
            </div>
            <div style="grid-row: 3; grid-column: 3">{{order?.cableDetection?.power?.date|date: 'dd.MM.yy HH:mm'}}</div>
            <div class="tele">Telekabler</div>
            <div style="grid-row: 4; grid-column: 2">
              <mat-icon *ngIf="order?.cableDetection?.tele"
                [ngClass]="{'active' : order?.cableDetection?.tele?.status === '0', 'detected' : order?.cableDetection?.tele?.status === '1'}">
                {{getCableStatusIcon(order?.cableDetection?.tele?.status)}}
              </mat-icon>
              <mat-icon color='accent' *ngIf="!order?.cableDetection?.tele"> help </mat-icon>
            </div>
            <div style="grid-row: 4; grid-column: 3">{{order?.cableDetection?.tele?.date|date: 'dd.MM.yy HH:mm'}}</div>
            <div class="other">Andre kabler:</div>
            <div style="grid-row: 5; grid-column: 2">
              <mat-icon *ngIf="order?.cableDetection?.other"
                [ngClass]="{'active' : order?.cableDetection?.other?.status === '0', 'detected' : order?.cableDetection?.other?.status === '1'}">
                {{getCableStatusIcon(order?.cableDetection?.other?.status)}}
              </mat-icon>
              <mat-icon color='accent' *ngIf="!order?.cableDetection?.other"> help </mat-icon>
            </div>
            <div style="grid-row: 5; grid-column: 3">{{order?.cableDetection?.other?.date|date: 'dd.MM.yy HH:mm'}}</div>
            <div class="mapSendt">Sendt kart</div>
            <div style="grid-column: 2; grid-row:6">{{order?.cableDetection?.mapSendt|date: 'dd.MM.yy HH:mm'}}</div>
            <div class="comment">Kommentar kabel</div>
            <div style="grid-column: 1/9; grid-row:8">
              <p style="font-style: italic">
                <mat-icon>format_quote</mat-icon>{{order?.cableDetection?.comment}}
              </p>

            </div>
          </div>
          <div class="varslingsplan-title"> Varslingsplan: <br />Arb. varslingsnr: <br /> Aktivering VTS: </div>
          <div class="varslingsplan"> {{ order?.arbEks }}<br />
            <div *ngIf="order?.arbIdUrl !== undefined && order?.arbIdUrl != ''; else elseBlock">
              <a class="link" href={{order?.arbIdUrl}} target="_blank">{{order?.arbId}} </a>
            </div>
            <ng-template #elseBlock>
              {{order?.arbId}}
            </ng-template>

            <br />{{order?.activationVTS}}
          </div>
          <div class="customer-title">Kunde</div>
          <div class="customer">{{order?.custName}}</div>
          <div class="contact-title">Kontakt</div>
          <div class="contact">
            <mat-chip (click)="openContact()">{{order?.custContact}}</mat-chip>
          </div>
          <div class="status-title">Status</div>
          <div class="status">{{order?.status | orderStatus}}</div>
        </div>
        <div class="card-actions">
          <span *ngIf="parcels && parcels?.length>0">
            <a *ngIf="parcels[0]?.gpsStartPos && parcels[0]?.gpsStartPos[0]" type="button" mat-button
              [href]="openNavigationApp(parcels[0].gpsStartPos,order?.location)">Naviger til <mat-icon
                aria-label="shortcut">navigation</mat-icon>
            </a>
            <span *ngIf="!parcels[0]?.gpsStartPos || !parcels[0]?.gpsStartPos[0]">Ingen
              posisjon</span>
          </span>
        </div>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel [hideToggle]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Parselloversikt<span *ngIf="parcels?.length>0">&nbsp;({{parcels?.length}})</span>
          
        </mat-panel-title>
        <mat-panel-description>
          &nbsp;
          <mat-icon [ngClass]="{'active' : parcels?.length > 0}">assignment</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="buttonBar">
        <button class="newParcelBtn" mat-raised-button color="primary" (click)="newParcel()"
          *ngIf="order?.status < 7 ">Ny tom parsell</button>
        <button class="unntaParcelBtn" mat-raised-button color="primary" (click)="unntaParcel()"
          *ngIf="order?.status < 7 ">Unnta fra godkjenning</button>
        <button class="showArticlesBtn" mat-raised-button color="primary" (click)="openArticleDialog()">Vis artikler</button>
          
      </div>
      <app-parcels-table #parcelsTable [orderUnid]="orderUnid" (allotmentsLoaded)="onAllotmentsLoaded($event)"
        (selectParsellEmitter)="onParsellSelected($event)"></app-parcels-table>
      <!--</mat-list>-->
    </mat-expansion-panel>
    <mat-expansion-panel [hideToggle]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Vedlegg<span *ngIf="order?.attachments?.length>0">&nbsp;({{order?.attachments.length}})</span>
        </mat-panel-title>
        <mat-panel-description>
          &nbsp;
          <mat-icon [ngClass]="{'active' : order?.attachments?.length > 0}">description</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <table *ngIf="order && order.attachments" mat-table [dataSource]="order?.attachments" style="width: 100%">

        <ng-container matColumnDef="filename">
          <th mat-header-cell *matHeaderCellDef> Filnavn </th>
          <td mat-cell *matCellDef="let attachment">
            <span class="mobile-label">Filnavn:</span>
            <mat-icon mat-list-icon>{{getFileIcon(attachment)}}</mat-icon>&nbsp;&nbsp;<span *ngIf="isElectron"
              style="cursor: pointer">{{attachment.filename}}</span><a *ngIf="!isElectron" [href]="attachment.url" target="_blank">{{attachment.filename}}</a>
          </td>
        </ng-container>


        <ng-container matColumnDef="modified">
          <th mat-header-cell *matHeaderCellDef> Endret </th>
          <td mat-cell *matCellDef="let attachment">
            <span class="mobile-label"> Endret:</span>
            {{attachment.modified|date}}
          </td>
        </ng-container>


        <ng-container matColumnDef="fileSize">
          <th mat-header-cell *matHeaderCellDef> Størrelse </th>
          <td mat-cell *matCellDef="let attachment">
            <span class="mobile-label"> Størrelse:</span>
            {{attachment.fileSize/1024|number: '1.0-2'}}kB
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="attachmentColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: attachmentColumns;" (click)="openAttachment(row)"></tr>        
      </table>      
    </mat-expansion-panel>
    <mat-expansion-panel [hideToggle]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Bilder<span *ngIf="images?.length>0">&nbsp;({{images.length}})</span>
        </mat-panel-title>
        <mat-panel-description>
          &nbsp;
          <mat-icon [ngClass]="{'active' : images?.length > 0}">description</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <mat-grid-list cols="4" rowHeight="2:1">
        <ng-container *ngIf="isElectron">
          <mat-grid-tile  *ngFor="let image of images"><a (click)="openImage(image)"><img [src]="getImgUrl(image)" style="cursor: pointer" width="95%" class="orderImage"></a><button (click)="deleteImage(image)" mat-icon-button aria-label="Slett" class="deleteIcon"><mat-icon>delete</mat-icon></button></mat-grid-tile>
        </ng-container>
        <ng-container *ngIf="!isElectron">
          <mat-grid-tile *ngFor="let image of images"><a [href]="getImgUrl(image)" target="_blank"><img [src]="getImgUrl(image)" style="cursor: pointer" width="95%" class="orderImage"></a><button (click)="deleteImage(image)" mat-icon-button aria-label="Slett" class="deleteIcon"><mat-icon>delete</mat-icon></button></mat-grid-tile>
        </ng-container>
      </mat-grid-list>
      <button class="newParcelBtn" mat-raised-button color="primary" (click)="addImage()">Legg til bilde</button>
    </mat-expansion-panel>
    <mat-expansion-panel [hideToggle]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Ordrelinjer<span *ngIf="orderlines?.length>0">&nbsp;({{orderlines?.length}})</span>
        </mat-panel-title>
        <mat-panel-description>
          &nbsp;
          <mat-icon  [ngClass]="{'active' : orderlines?.length > 0}">receipt</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="buttonBar" *ngIf="order?.status < 7">
        <button class="newParcelBtn" mat-raised-button color="primary" (click)="addArticleClick()">Ny
          ordrelinje</button>
      </div>
      <table mat-table [dataSource]="orderlines" style="width: 100%" *ngIf="orderlines.length>0">

        <ng-container matColumnDef="artName">
          <th mat-header-cell *matHeaderCellDef> Artikkel </th>
          <td mat-cell *matCellDef="let art">
            <span class="mobile-label">Artikkel:</span>
            <a class="link" (click)="addArticleClick(art)" style="cursor: pointer">{{art.artNum}} {{art.artInfo[0]}}</a>
          </td>
        </ng-container>


        <ng-container matColumnDef="offerCount">
          <th mat-header-cell *matHeaderCellDef> Tilbud </th>
          <td mat-cell *matCellDef="let art">
            <span class="mobile-label"> Tilbud:</span>
            {{art.offerCount}}
          </td>
        </ng-container>


        <ng-container matColumnDef="regCount">
          <th mat-header-cell *matHeaderCellDef> Registrert </th>
          <td mat-cell *matCellDef="let art">
            <span class="mobile-label"> Registrert:</span>
            {{art.count}}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="orderlineColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: orderlineColumns;"></tr>
      </table>
    </mat-expansion-panel>
    <mat-expansion-panel [hideToggle]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Kommentarer<span *ngIf="orderComments?.length>0">&nbsp;({{orderComments?.length}})</span>
        </mat-panel-title>
        <mat-panel-description>
          &nbsp;
          <mat-icon [ngClass]="{'active' : order?.woComment}">question_answer</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="buttonBar">
        <button class="newParcelBtn" mat-raised-button color="primary" (click)="addComment()">Ny Kommentar</button>
      </div>
      <h3>Info til laget</h3>
      <p style="font-style: italic">
        <mat-icon>format_quote</mat-icon>{{order?.woComment}}
      </p>
      <h3>Kommentarer fra laget</h3>
      <div *ngFor="let comment of orderComments">
        <p>Fra: {{comment.user}}, {{comment.lastModified|date: 'dd.MM.yy HH:mm'}}</p>
        <p style="font-style: italic">
          <mat-icon>format_quote</mat-icon>{{comment.body}}
          <a (click)="editComment(comment)" *ngIf="comment.editable" style="cursor: pointer">
            <mat-icon>edit</mat-icon>
          </a>
        </p>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel [hideToggle]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Timeføringer <span *ngIf="weekHours?.length>0">&nbsp;({{weekHours?.length}})</span>
        </mat-panel-title>
        <mat-panel-description>
          Totalt antall timer {{totalHoursOnOrder.hoursWork}}
          &nbsp;
          <mat-icon [ngClass]="{'active' : weekHours?.length>0}">alarm</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="week-hours-grid">
        <div>Initialer</div>
        <div>Dato</div>
        <div>Timer</div>
        <div>Herav 50%</div>
        <div>Herav nattarbeid</div>
        <ng-container *ngFor="let weekHour of weekHours">
          <ng-container *ngFor="let member of weekHour.teamMembers">
            <div>{{member.initials}}</div>
            <div>{{weekHour.haDate | date:'dd.MM.yyyy'}}</div>
            <div>{{weekHour.hoursWork}}</div>
            <div>{{weekHour.hoursOvertime50}}</div>
            <div>{{weekHour.hoursNight}}</div>
          </ng-container>
        </ng-container>
        <div class="week-hours-sum">
          <div>Sum</div>
          <div>&nbsp;</div>
          <div>{{totalHoursOnOrder.hoursWork}}</div>
          <div>{{totalHoursOnOrder.hoursOvertime50}}</div>
          <div>{{totalHoursOnOrder.hoursNight}}</div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
  <!--  <div class="function-shortcuts" [class.mobile-view]="isMobileView">
    <mat-card mat-ripple (click)="navigateTo('measure/'+order?.unid)">
      <mat-card-header>
        <mat-icon aria-label="shortcut">settings_input_antenna</mat-icon> -->
  <!--Alternatives: wifi, bluetooth, satellite -->
  <!--</mat-card-header>
      <mat-card-content>
        <h3>REGISTRERING</h3>
        Velg parsell og registrer punkter
      </mat-card-content>
    </mat-card>
    <mat-card mat-ripple (click)="navigateTo('sja/'+order?.unid)">
      <mat-card-header>
        <mat-icon aria-label="shortcut">library_books</mat-icon> -->
  <!--Alternatives: collections, folder, library_books -->
  <!--</mat-card-header>
      <mat-card-content>
        <h3>SJA og SKILTOGG</h3>
        Sikker jobbanalyse og skilting
      </mat-card-content>
    </mat-card>
    <mat-card mat-ripple (click)="goToTimeSheetRegistration(order)">
      <mat-card-header>
        <mat-icon aria-label="shortcut">timer</mat-icon> -->
  <!--Alternatives: watch, access_timer-->
  <!-- </mat-card-header>
      <mat-card-content>
        <h3>TIMEFØRING</h3>
        Registrer timer for alle på laget
      </mat-card-content>
    </mat-card>
    <mat-card mat-ripple (click)="navigateTo(order?.unid+'/approve')">
      <mat-card-header>
        <mat-icon aria-label="shortcut">send</mat-icon> -->
  <!--Alternatives: gavel, checkbox-->
  <!-- </mat-card-header>
      <mat-card-content>
        <h3>GODKJENN ORDRE</h3>
        Her kan du godkjenne ordren
      </mat-card-content>
    </mat-card>
  </div>
</div> -->
  <div class="sticky-footer">
    <form>
      <button mat-raised-button type="button" color="primary" [disabled]="!order?.unid"
        (click)="navigateTo('measure/'+order?.unid)">
        <mat-icon aria-label="shortcut">settings_input_antenna</mat-icon>
        <span class="label"> Registrering </span>
      </button>
      <button mat-raised-button type="button" color="primary" [disabled]="!order?.unid"
        (click)="navigateTo('sja/'+order?.unid)">
        <mat-icon aria-label="shortcut">library_books</mat-icon>
        <span class="label"> Sja og Skiltogg </span>
      </button>
      <button mat-raised-button type="button" color="primary" [disabled]="!order?.unid"
        (click)="goToTimeSheetRegistration(order)">
        <mat-icon aria-label="shortcut">timer</mat-icon>
        <span class="label"> Timeføringer </span>
      </button>
      <button mat-raised-button type="button" color="primary" [disabled]="!order?.unid"
        (click)="navigateTo(order?.unid+'/approve')">
        <mat-icon aria-label="shortcut">send</mat-icon>
        <span class="label"> Godkjenn ordre </span>
      </button>
    </form>

  </div>
  <app-road-information></app-road-information>