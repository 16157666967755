<div class="login" [class.mobile-view]="isMobileView">
  <mat-card class="login-card" [class.mat-elevation-z0]="isMobileView">
    <mat-card-header>
      <mat-icon mat-card-avatar aria-label="Sign in">fingerprint</mat-icon>
      <mat-card-title>Logg inn</mat-card-title>
      <mat-card-subtitle>Velg bruker og skriv inn passord for å logge inn.</mat-card-subtitle>
    </mat-card-header>

    <mat-card-content>
      <form [formGroup]="loginForm" class="login-form">
        <div [class.between]="!users && counter <= counterLimit">
          <mat-form-field class="login-full-width">
            <mat-select placeholder="Velg bruker" formControlName="user" name="users" required="true" *ngIf="electronService.isElectron()">
              <mat-option *ngFor="let user of users" [value]="user">
                {{user.fullName}}
              </mat-option>
            </mat-select>
            <input matInput placeholder="Epost" formControlName="user" name="users" required="true" *ngIf="!electronService.isElectron()">
          </mat-form-field>

          <mat-spinner *ngIf="!users && counter <= counterLimit" diameter="30"></mat-spinner>
        </div>
        <mat-form-field class="login-full-width">
          <input matInput placeholder="Password" formControlName="password" required="true" type="password" (keyup.enter)="onKey()">
        </mat-form-field>
        <mat-error>
            {{errorMessage}}
        </mat-error>
        <mat-progress-bar mode="buffer" *ngIf="isLoggingIn" color="primary"></mat-progress-bar>
      </form>
    </mat-card-content>
    <mat-card-actions align="end">
      <button mat-raised-button color="primary" [disabled]="!loginForm.valid || isLoggingIn" (click)="loginUser()">Logg inn</button>
    </mat-card-actions>
  </mat-card>
</div>
